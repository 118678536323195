



.LoaderAnimation{
    width: 150px;
    height: 150px;
}

.topFixedBanner{

    min-height: 500px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.topBannerOverlay{
    position: absolute;
    min-height: 500px;
    width: 100%;
    background: rgba(0,0,0,0.7);
}

.topContent{
    margin-top: 12rem;
}
.topTitle{
      font-family: 'Montserreat',sans-serif;
      font-weight: 700;
      color: #ffffff;
      font-size: 48px;
  }
.topSubTitle{
    font-family: 'Raleway',sans-serif;
    font-weight: 600;
    color: #ffffff;
    font-size: 22px;
}
.serviceMainTitle{
    color: #0073E6;
    margin-top: 5rem;
    margin-bottom: 3rem;
    font-family: 'Montserreat',sans-serif;
    font-size: 28px;
    font-weight: 600;
}
.serviceName{
    margin-top: 1rem;
    font-family: 'Raleway',sans-serif;
    font-size: 22px;
    font-weight: 600;
}
.serviceDescription{
    color: #2B2B2B;
    font-family: 'Raleway',sans-serif;
    font-size: 14px;
    font-weight: 400;
}
.serviceCard{
    margin: 5px 5px 5px 5px;
    height: 22rem;
    padding: 60px 30px 60px 30px;
    box-shadow: 0 0 4px 0 rgba(0,115,230,0.2);
}
.serviceCard:hover{
    box-shadow: 0 0 8px 0 rgba(0,115,230,0.2);
}
.navTitle{
    text-decoration: none;
    font-family: 'Montserreat',sans-serif;
    color: #ffffff !important;
    font-weight: 700;
    font-size: 24px;
}
.navTitle:hover{
    text-decoration: none;
}

.navTitleScroll{
    text-decoration: none;
    font-family: 'Montserreat',sans-serif;
    color: #0073E6 !important;
    font-weight: 700;
    font-size: 24px;
}
.navTitleScroll:hover{
    text-decoration: none;
}

.navBackground{
    -webkit-transition:background-color 400ms linear ;
    -moz-transition:background-color 400ms linear ;
    -o-transition:background-color 400ms linear ;
    transition:background-color 400ms linear ;
    background-color: rgba(00,00,00,0.0) !important;
}
.navBackgroundScroll{
    -webkit-transition:background-color 400ms linear ;
    -moz-transition:background-color 400ms linear ;
    -o-transition:background-color 400ms linear ;
    transition:background-color 400ms linear ;
    background-color: #ffffff !important;
    box-shadow: 0 0 6px 0 rgba(0,115,230,0.3);
}

.navItem{
    font-size: 16px;
    text-decoration: none !important;
    font-family: 'Montserreat',sans-serif;
    font-weight: 400;
    color: #ffffff;
}
.navItemScroll{
    font-size: 16px;
    text-decoration: none !important;
    font-family: 'Montserreat',sans-serif;
    font-weight: 400;
    color: #2B2B2B !important;
}

.des{
    font-weight: 400;
    color: #2B2B2B;
    font-family: 'Raleway',sans-serif;
    font-size: 14px;
}


.SummarySection{
    margin-top: 5rem;
}

.SummaryBanner{
    background-image: url("../image/banner.jpg");
    min-height: 350px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.SummaryBannerOverlay{
    position: absolute;
    min-height: 350px;
    width: 100%;
    background: rgba(0,0,0,0.7);
}

.countNumber{
    color: #ffffff;
    font-family: 'Montserreat',sans-serif;
    font-size: 48px;
    font-weight: 600;
}
.countTitle{
    color: #ffffff;
    font-family: 'Raleway',sans-serif;
    font-weight: 600;
    font-size: 22px;
}

.countSection{
    margin-top: 16%;
}

.cardTitle{
    font-weight: 600;
    font-size: 22px;
    color: #2B2B2B;
    font-family: 'Raleway',sans-serif;
}
.cardSubTitle{
    font-weight: 400;
    font-size: 14px;
    color: #2B2B2B;
    font-family: 'Montserreat',sans-serif;
}
.iconBullet{
    color: #0073E6;
    font-size: 16px;
}

.workCard{
    margin-top: 14%;
    padding: 10px 10px 10px 10px;
    border-radius: 2px;
}

.projectCard{
    border-radius: 2px;
    border: none !important;
    margin: 2px 2px 2px 2px;
    box-shadow: 0 0 4px 0 rgba(0,115,230,0.2);
}
.projectCard:hover{
    box-shadow: 0 0 8px 0 rgba(0,115,230,0.2);
}

.projectCardTitle{
    font-family: 'Montserreat',sans-serif;
    font-size: 18px;
    font-weight: 600;
}

.projectCardDes{
    font-family: 'Raleway',sans-serif;
    font-size: 15px;
    font-weight: 400;
}

.courseImg{
width: 100%;
}
.courseTitle{
    font-family: 'Montserreat',sans-serif;
    font-size: 18px;
    font-weight: 600;
}
.courseDes{
    font-family: 'Raleway',sans-serif;
    font-size: 14px;
    font-weight: 400;
}
.courseDetails{
    color: #0073E6;
    text-decoration: none;
    font-family: 'Raleway',sans-serif;
    font-size: 18px;
    font-weight: 600;
}
.courseDetails:hover{
    text-decoration: none;
}
.videoCard{
    box-shadow: 0 0 4px 0 rgba(0,115,230,0.2);
    padding: 6rem 3rem 6rem 3rem;
    background-color: #ffffff;
    margin: 50px auto;
}
.playBtn{
    color: #0073E6;
    font-size: 48px;
    cursor: pointer;
}
.videoTitle{
    color: #0073E6;
    font-family: 'Montserreat',sans-serif;
    font-size: 22px;
    font-weight: 600;
}
.videoDes{
    font-family: 'Raleway',sans-serif;
    font-size: 16px;
    font-weight: 400;
}

.circleImg{
    margin: 0 auto;
    width: 100px;
    height: 100px;
    border: 1px solid #0073E6;
    border-radius: 50%;
}

.footerSection{
        box-shadow: 0 0 4px 0 rgba(0,115,230,0.2);
        margin-top: 5rem;
}

.socialLink{
    text-decoration: none;
    color: #0073E6;
    font-family: 'Raleway',sans-serif;
    font-size: 14px;
    font-weight: 600;
}

.socialLink:hover{
    text-decoration: none;
    color: #2B2B2B;

}
.footerLink{
    color: #2B2B2B;
    font-weight: 400;
    font-size: 14px;
    text-decoration: none;
    font-family: 'Raleway',sans-serif;
}

.footerLink:hover{
    color: #0073E6;
    text-decoration: none;
}

.copyrightSection{
    padding: 20px 20px 20px 20px;
    background-color: #0073E6;
}
.copyrightLink{
    color: #ffffff;
    font-family: 'Montserreat',sans-serif;
    font-weight: 400;
    font-size: 14px;
    text-decoration: none;
}
.copyrightLink:hover{
    color: #2B2B2B;
    text-decoration: none;
}

.topFixedPage{
    background-image: url("../image/banner.jpg");
    min-height: 280px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.topPageOverlay{
    position: absolute;
    min-height: 280px;
    width: 100%;
    background: rgba(0,0,0,0.7);
}

.topPageContent{
    margin-top: 8rem;
}

.topPageContentCourse{
    margin-top: 6rem;
}

.CourseFullTitle{
    color: white;
    font-family: Raleway;
    font-size: 28px;
    font-weight: 600;
}
.CourseSubTitle{
     color: white;
     font-family: Raleway;
     font-size: 16px;
     font-weight: 400;
 }

.CourseDes{
    color: white;
    font-family: Raleway;
    font-size: 14px;
    font-weight: 400;
}


.pageTitle{
    font-family: 'Raleway',sans-serif;
    font-weight: 600;
    color: #ffffff;
    font-size: 28px;
}

.form-control:focus{
    border-radius: 2px !important;
    border-color: #0073E6 !important;
    box-shadow: 0 0 4px 0 rgba(0,115,230,0.2) !important;
}

.link-style{
color: white !important;
text-decoration: none!important;

}

.link-style:hover{
    color: white !important;
    text-decoration: none!important;
}
.LoaderAnimation{
    width: 150px;
    height: 150px;
}
.select-class{
    width: 100%;
    margin: 0 auto;
}
.btnCustomer{
    width: 40%;
    margin-right: 10px;
}

.text-justify{
    text-align: justify;
}
.text-left{
    text-align: left;
}
.text-right{
    text-align: right;
}
.mainBackground{
    width: 90%;
    min-height: 700px;
    background: #bdc2c9 url("../image/coil.jpg");
    margin: 10px auto;
}

.purchaseDiv{
    margin: 5px auto;
    background: white;
    border-radius: 10px;
    border-color: red !important;
    border-style: groove;
    min-height: 600px;
    width: 100%;

}
.purchaseLongDiv{
    margin: 5px auto;
    background: white;
    border-radius: 10px;
    border-color: red !important;
    border-style: groove;
    min-height: 1000px;
    width: 100%;

}
.purchaseSmallDiv{
    margin: 5px auto;
    background: white;
    border-radius: 10px;
    border-color: red !important;
    border-style: groove;
    min-height: 100px;
    width: 100%;

}
.navItem{
    text-decoration: none !important;
}
.inputCalendar{
    height: 40px;
    width: 100%;
    font-size: 16px;
}
.productionTable{
    font-size: 18px;
    font-family: 'Montserrat',sans-serif;
    font-weight: 600;
}
.orderTblDiv{
    background: white;
    border-radius: 10px;
    border-color: #1e1d1d !important;
    border-style: groove;
    width: 100%;
    margin-bottom: 5px;
    min-height: 300px;

}
.customfontsize{
    font-size: 20px;
    text-align: center;
    min-width: border-box;
}
.tableDiv{
    background: white;
    min-height:300px ;
    min-width:300px ;
    overflow: scroll;
    max-height: 300px;
}

.tableDivBorder{

    border-radius: 5px;
    border-color: #0051ff !important;
    border-style:groove;
    visibility: hidden;

}
.Visibility{

    visibility: hidden ;
}
.VisibilityHidden{
    visibility: hidden;
    min-height: 10px;
    overflow: hidden;


}
.invoice{
    min-width: 400px;

    min-height: 400px;
}